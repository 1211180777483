body {
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}

p {
    font-size: 16px;
}

.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.MuiContainer-root.form-container {
    margin-top: 24px;
}


/* .MuiTypography-root.form-title {
    font-family: 'Times New Roman', Times, serif;
} */

.form-error {
    color: crimson;
}

label {
    font-weight: bold;
}

.help-text {
    border-radius: 4px;
    background-color: #E9E9E9;
    padding: 10px;
}

.help-text-em {
    font-style: italic;
}

.field-container {
    padding-top: 20px;
}

.MuiButton-root.form-submit {
    color: white;
    background-color: #000000;
}

.MuiButton-root.form-submit:hover {
    color: white;
    background-color: #FF5056;
}

.MuiButton-root.modal-button-primary {
    color: white;
    background-color: #FF5056;
}

.MuiButton-root.modal-button-primary:hover {
    color: white;
    background-color: #000000;
}

.MuiButton-root.modal-button-secondary {
    color: white;
    background-color: #000000;
}

.MuiButton-root.modal-button-secondary:hover {
    background-color: #FF5056;
}

.MuiTypography-root.MuiTypography-h6 {
    font-family: 'Times New Roman', Times, serif;
}

.MuiTypography-root.MuiTypography-body1 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.87)
}